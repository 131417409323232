import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  closeMessageLoading,
  confirm,
  fetchNotifications,
  messageError,
  messageLoading,
  messageOk,
} from "../../modules/utils/actions";
import { connect } from "react-redux";
import {
  deleteUserMine,
  unBindUserMine,
  getCPError,
  getErrorType,
  getFaultTime,
  getInviteNodes,
  getMachineError,
  getUserMine,
  resubmitUserMine,
  setName,
  updateMachineMonitor,
} from "../../modules/userMine/actions";
import { Title } from "../../modules/title";
import Tab from "../../modules/tab";
import Table from "../../modules/table";
import {
  InviteeListItem,
  LinkListItem,
  MineNodeListItem,
} from "../../modules/listItem";
import { MineNodeDetail } from "../../modules/nodeDetail";
import EasyAnnounce from "../../modules/easyAnnounce";

const styles = (theme) => ({});

function MyReward(props) {
  const {
    userMineList,
    userMineListLoading,
    inviteNodes,
    inviteNodesLoading,
    onRequire,
    onResubmit,
    confirm,
    onDelete,
    onSetNickname,
    notifications,
    faultTime,
    updateMachineMonitor,
  } = props;
  const [openedNodeInfo, setOpenedNodeInfo] = useState(null);

  useEffect(() => {
    onRequire();
  }, []);

  let sortedInviteNodes = [...inviteNodes];
  sortedInviteNodes.sort(
    (a, b) =>
      (b.inviter_reward_yesterday || 0) - (a.inviter_reward_yesterday || 0)
  );

  const myNodesTableParam = {
    list: userMineList.map((v) => ({
      ...v,
      ip: v.ssh || "",
      pearID: v.pear_id || "",
      online: v.online,
      approved: (v.approve_status || 0) === 2,
      onlineTime: v.online_time || 0,
      rewardYesterday: (v.reward_yesterday || 0) / 100,
      totalReward: (v.reward_total || 0) / 100,
      waiting:
        (v.reward_yesterday || 0) < 0 ||
        ((v.reward_yesterday || 0) === 0 && v.reward_status === 5), //结算中
    })),
    loading: userMineListLoading,
    render: (v) => (
      <MineNodeListItem
        nodeInfo={v}
        key={v.ip || v.id}
        onClick={() => setOpenedNodeInfo(v)}
      />
    ),
  };
  const inviteeTableParam = {
    list: sortedInviteNodes.map((v) => ({
      ...v,
      userName: v.user_name || "",
      nodes: (v.macs || []).length,
      rewardYesterday: (v.inviter_reward_yesterday || 0) / 100,
      totalReward: (v.inviter_reward_total || 0) / 100,
    })),
    loading: inviteNodesLoading,
    render: (v) => <InviteeListItem nodeInfo={v} key={v.id} />,
  };
  return (
    <div>
      <Title>CDN节点</Title>
      <EasyAnnounce notifications={notifications} />
      <Tab
        data={
          sortedInviteNodes.length > 0
            ? [
                {
                  label: "我的节点",
                  content: <Table {...myNodesTableParam} key={0} />,
                },
                {
                  label: "推广节点",
                  content: <Table {...inviteeTableParam} key={1} />,
                },
              ]
            : [
                {
                  label: "我的节点",
                  content: <Table {...myNodesTableParam} key={2} />,
                },
              ]
        }
      />
      <MineNodeDetail
        open={openedNodeInfo != null}
        onClose={() => setOpenedNodeInfo(null)}
        nodeInfo={openedNodeInfo}
        faultTime={faultTime}
        onResubmit={(id) => onResubmit(id)}
        onDelete={(id) => {
          confirm(
            `确定删除CDN？该操作无法撤回`,
            () => {
              onDelete(id);
              setOpenedNodeInfo(null);
            },
            false
          );
        }}
        onSetNickname={(id, nickname) => onSetNickname(id, nickname)}
        updateMachineMonitor={updateMachineMonitor}
        {...props}
        confirm={confirm}
      />
      <Title>添加节点</Title>
      <LinkListItem
        title="绑定自有节点"
        subtitle="将有专人完成程序部署，验收成功后，即可产生收益"
        to="/home/submit_node"
      />
      {/*<ActionListItem title="复制邀请链接" subtitle="朋友通过链接注册账号后，绑定的节点会成为你的推广节点" onClick={()=>this.copyText(`https://openfogos.com?usermine_inviter=${inviteCode}`)}/>*/}
    </div>
  );
}

const formatDate = function (date) {
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  var d = date.getDate();
  d = d < 10 ? "0" + d : d;
  return y + "-" + m + "-" + d;
};

const getLastDate = function () {
  var dateTime = new Date();
  dateTime = dateTime.setDate(dateTime.getDate() - 1);
  dateTime = new Date(dateTime);
  return dateTime;
};

const mapStateToProps = (state) => {
  return {
    errorTypeMap: state.userMine.errorTypeMap || {},
    CPErrorMap: state.userMine.cpErrorMap || {},
    machineErrorMap: state.userMine.machineErrorMap || {},
    userMineList: state.userMine.userMineList || [],
    userMineListLoading: state.userMine.getUserMineStatus === "loading",
    inviteNodes: state.userMine.inviteNodes || [],
    inviteNodesLoading: state.userMine.getInviteNodesStatus === "loading",
    notifications: (state.utils.notifications || {})["CDN节点"] || [],
    faultTime: state.userMine.faultTime || formatDate(getLastDate()),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(getUserMine());
      dispatch(getInviteNodes());
      dispatch(fetchNotifications("CDN节点"));
      dispatch(getFaultTime());
      dispatch(getCPError());
      dispatch(getErrorType());
    },
    onResubmit: (id) => {
      dispatch(
        resubmitUserMine(id, {
          success: () => {
            dispatch(messageOk("提交成功"));
            dispatch(getUserMine());
          },
          error: () => {
            dispatch(messageError("提交失败"));
          },
        })
      );
    },
    onDelete: (node_id, id) => {
      const action = {
        delete: {
          text: "删除",
          methods: (success, error) => deleteUserMine(id, { success, error }),
        },
        unbind: {
          text: "解绑",
          methods: (success, error) =>
            unBindUserMine({ node_id }, { success, error }),
        },
      };
      const currentAction = node_id ? action.unbind : action.delete;
      dispatch(messageLoading(`${currentAction.text}中...`));
      dispatch(
        currentAction.methods(
          () => {
            dispatch(messageOk(`${currentAction.text}成功`));
            dispatch(getUserMine());
          },
          () => {
            dispatch(messageError(`${currentAction.text}失败`));
          }
        )
      ).finally(() => {
        dispatch(closeMessageLoading());
      });
    },
    onSetNickname: (id, name) => {
      dispatch(
        setName(id, name, {
          success: () => {
            dispatch(messageOk("设置成功"));
            dispatch(getUserMine());
          },
          error: () => {
            dispatch(messageError("设置失败"));
          },
        })
      );
    },
    confirm: (message, callback, hasVcode) => {
      dispatch(confirm(message, callback, hasVcode));
    },
    messageOk: (dataStr) => {
      dispatch(messageOk(dataStr));
    },
    updateMachineMonitor: (id, status) => {
      dispatch(
        updateMachineMonitor(id, status, {
          success: () => {
            dispatch(getUserMine());
          },
        })
      );
    },
    getMachineError: (data) => {
      dispatch(
        getMachineError(
          {
            success: () => {
              dispatch(messageOk("请求成功！"));
            },
          },
          data
        )
      );
    },
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MyReward)
);
